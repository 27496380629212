import axios from "axios";
import { message } from "antd";
import Cookies from "js-cookie";

const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;
const TOKEN = sessionStorage.getItem("sm_register_token") || Cookies.get("member-token");

const baseURL = "https://uatapi.southmatrimony.com/";
const client = axios.create({
    baseURL: "https://uatapi.southmatrimony.com/",
    timeout: TIMEOUT,
    headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${TOKEN}`,
    }
});

client.interceptors.request.use(function (config) {
    return config;
});
client.interceptors.response.use(
    function (response) {

        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            window.location = "/";
        } else if (error.response.status !== 200) {
            message.error(error.response.data.data);
        } else {
            console.error(error);
        }
        return Promise.reject(error);
    }
);



const fetchAwsImage = async (imgPath) => {
    const data = await fetch(`${baseURL}getFileStream?path=${imgPath}`, {
        method: "get",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
        }
    })
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
    return data;
}

export const viewAwsDoc = async (imgPath) => {
    await fetch(`${baseURL}getFileStream?path=${imgPath}`, {
        method: "get",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
        }
    })
        .then(response => response.blob())
        .then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = "file.pdf";
            alink.click();
        })
}

const publicfetchAwsImage = async (imgPath) => {
    const data = await fetch(`${baseURL}public/getFileStream?path=${imgPath}`, {
        method: "get",
        headers: {
            // "Authorization": `Bearer ${TOKEN}`,
        }
    })
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
    return data;
}

const fetchAwsPdf = async (imgPath) => {
    const data = await fetch(`${baseURL}getFileStream?path=${imgPath}`, {
        method: "get",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
        }
    })
        .then(response => response.blob())
        .then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = "file.pdf";
            window.open(fileURL);
            message.success("Pdf Downloaded Successfully");
            alink.click();
        });
    // .then(blob => URL.createObjectURL(blob))
    return data;
}

export {
    fetchAwsImage,
    publicfetchAwsImage,
    fetchAwsPdf
};