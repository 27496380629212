import { message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";

const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;
let googleData = Cookies.get("google-data") && JSON.parse(Cookies.get("google-data")) ;
const TOKEN = sessionStorage.getItem("sm_register_token") || googleData?.token;


const client = axios.create({
    baseURL: "https://uatapi.southmatrimony.com/",
    timeout: TIMEOUT,
    headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${TOKEN}`,
    }
});
// 9829398792

client.interceptors.request.use(function (config) {
    return config;
});

client.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            // window.location = "/";
        } else if (error.response.status !== 200) {
            message.error(error.response.data.data);
        } else {
            console.error(error);
        }
        return Promise.reject(error);
    }
);

const memberProfiles = async (formData) => {
    const { data } = await client.post(`userProfile/viewProfiles`, formData);
    return data;
}

const memberRecentlyViewed = async (id) => {
    const { data } = await client.post(`matches/recentlyViewedProfiles/${id}`);
    return data;
}

const memberEditDefaultImage = async (formData) => {
    const { data } = await client.post(`uploadOrEditDefaultImage`, formData);
    return data;
}


const memberEditBasicInfo = async (formData) => {
    const { data } = await client.post(`/myProfileUser/editBasicDetails`, formData);
    return data;
}

const memberEditProfileImage = async (formData) => {
    const { data } = await client.post(`/updateProfileImage`, formData)
    return data;
}

const getMemberBasicInfo = async () => {
    const { data } = await client.get(`myProfile/getAboutUser`);
    return data;
}

const getMemberOwnProfile = async () => {
    const { data } = await client.get(`userProfile/ownUserDetails`);
    return data;
}

const memberEditFamilyDetails = async (formData) => {
    // var fData = JSON.parse(formData);
    const { data } = await client.post(`myProfile/editFamilyDetail`, formData);
    return data;
}

const getMemberFamilyDetails = async () => {
    const { data } = await client.get(`myProfile/getFamilyDetails`);
    return data;
}

const memberEditLifeStyleDetails = async (formData) => {
    const { data } = await client.post(`myProfile/editLifeStyleDetail`, formData);
    return data;
}

const getMemberLifeStyleDetails = async () => {
    const { data } = await client.get(`myProfile/getLifeStyleDetails`);
    return data;
}

const memberEditAstroDetails = async (formData) => {
    const { data } = await client.post(`myProfile/editAstroDetails`, formData);
    return data;
}

const getMemberAstroDetails = async () => {
    const { data } = await client.get(`myProfile/getAstroDetails`);
    return data;
}

const memberEditReligiousDetails = async (formData) => {
    const { data } = await client.post(`myProfile/editReligionDetails`, formData);
    return data;
}

const getMemberReligiousDetails = async () => {
    const { data } = await client.get(`myProfile/getReligiousDetails`);
    return data;
}

const memberEditEducationDetails = async (formData) => {
    const { data } = await client.post(`myProfile/editCareerDetails`, formData);
    return data;
}

const getMemberEducationDetails = async () => {
    const { data } = await client.get(`myProfile/getEducationDetails`);
    return data;
}

const memberEditLocationDetails = async (formData) => {
    const { data } = await client.post(`myProfile/editLocationDetailsByUser`, formData);
    return data;
}

const getMemberLocationDetails = async () => {
    const { data } = await client.get(`myProfile/getLocationDetails`);
    return data;
}

const memberEditSocialProfiles = async (formData) => {
    const { data } = await client.post(`myProfile/createOrEditSocialProfiles`, formData);
    return data;
}

const getMemberSocialProfiles = async () => {
    const { data } = await client.get(`myProfile/getSocialProfiles`);
    return data;
}

const memberEditPartnerPreferenceDetails = async (formData) => {
    const { data } = await client.post(`myProfile/editPartnerPreferenceDetails`, formData);
    return data;
}

const getMemberPartnerDetails = async () => {
    const { data } = await client.get(`myProfile/getPartnerPreferenceDetails`);
    return data;
}

const memberChangeEmail = async (formData) => {
    const { data } = await client.post(`/accountDetails/changeEmail`, formData);
    return data;
}

const memberDeactivateAccount = async (formData) => {
    const { data } = await client.post(`/myProfile/deactivateProfile`, formData);
    return data;
}

const memberProfileView = async (id) => {
    const { data } = await client.get(`/userProfile/viewCompleteProfile/${id}`);
    return data;
}

export {
    memberEditDefaultImage,
    memberEditBasicInfo,
    memberEditProfileImage,
    memberRecentlyViewed,
    getMemberBasicInfo,
    getMemberOwnProfile,
    memberEditFamilyDetails,
    memberProfiles,
    getMemberFamilyDetails,
    memberEditLifeStyleDetails,
    getMemberLifeStyleDetails,
    memberEditAstroDetails,
    getMemberAstroDetails,
    memberEditReligiousDetails,
    getMemberReligiousDetails,
    memberEditEducationDetails,
    getMemberEducationDetails,
    memberEditLocationDetails,
    getMemberLocationDetails,
    memberEditSocialProfiles,
    getMemberSocialProfiles,
    memberEditPartnerPreferenceDetails,
    getMemberPartnerDetails,
    memberChangeEmail,
    memberDeactivateAccount,
    memberProfileView
};


